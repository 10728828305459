import React from "react";
import { Review } from "../Reviews/Review";

const Reviews = () => {
  const [reviewData, setreviewData] = React.useState([]);
  const getreviewssData = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    fetch("/reviews-data.json", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setreviewData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getreviewssData();
  }, []);

  return (
    <div className="container mx-auto mt-5 px-5 flex flex-col items-center mb-5">
      <p className="text-3xl lg:w-1/2 text-center font-bold">ОТЗЫВЫ КЛИЕНТОВ</p>
      <p className="mt-2">{reviewData.length} Обзоры</p>
      <div className="grid grid-cols-1 justify-between gap-6 mt-16 flex-col lg:grid-cols-3">
        {reviewData.map((item, i) => {
          return (
            <Review
              key={i}
              review={item.review}
              username={item.username}
              profession={item.profession}
              rating={item.rating}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Reviews;
