import Background from "../../assets/images/Background.png";

const BackgroundImage = () => {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${Background})`,
        }}
        className="h-screen w-screen bg-cover bg-no-repeat absolute top-0 left-0 -z-50 bg-gray-800"
      ></div>
    </>
  );
};

export default BackgroundImage;
