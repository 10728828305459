import React from "react";
import "./Featured.css";

export const Featured = () => {
  return (
    <div className=" container mx-auto px-6 ">
      <div className=" grid grid-row lg:grid-cols-4 gap-x-4 ">
        <div className="featured h-auto lg:max-x-lg md:max-w-md sm:max-w-sm">
          <p className="pt-3 px-5 text-[40px] font-[600] text-[#545767]  ">
            01
          </p>
          <p className="px-5 mt-2 text-[20px]  font-extrabold text-white  ">
            Безопасно и надежно{" "}
          </p>
          <p className="px-5 mt-3 pb-5 font-mono ">
            {" "}
            Hydra Flasher использует частный скрипт для прошивки. Никакое
            стороннее ПО или скрипт не используются для прошивки.
          </p>
        </div>

        <div className="featured  h-auto lg:max-x-lg md:max-w-md sm:max-w-sm  ">
          <p className="number pt-3 px-5 text-[40px] font-[600] text-[#545767]  ">
            02
          </p>
          <p className="px-5 mt-2 text-[20px]  font-extrabold text-white  ">
            Блокировка отслеживания{" "}
          </p>
          <p className="px-5 mt-3 pb-5">
            {" "}
            Наш Flasher обходит адрес майнера для отправки Flash Dollar. Это
            поможет вам сохранить анонимность.
          </p>
        </div>

        <div className="featured  h-auto lg:max-x-lg md:max-w-md sm:max-w-sm  ">
          <p className="number pt-3 px-5 text-[40px] font-[600] text-[#545767]  ">
            03
          </p>
          <p className="px-5 mt-2 text-[20px]  font-extrabold text-white  ">
            Мгновенная вспышка{" "}
          </p>
          <p className="px-5 mt-3 pb-5">
            {" "}
            Вы можете мгновенно прошить свой кошелек. Не тратя время. И Flash
            длится 21 день.
          </p>
        </div>

        <div className="featured  h-auto lg:max-x-lg md:max-w-md sm:max-w-sm  ">
          <p className="number pt-3 px-5 text-[40px] font-[600] text-[#545767]  ">
            04
          </p>
          <p className="px-5 mt-2 text-[20px]  font-extrabold text-white  ">
            Продажа и обмен
          </p>
          <p className="px-5 mt-3 pb-5">
            {" "}
            Наш Flash Dollar можно перевести практически на любой кошелек,
            включая Binance. Вы можете торговать Flash Funds и превращать их в
            реальные доллары.
          </p>
        </div>
      </div>
    </div>
  );
};
