import React, { useState } from "react";
import styles from "./BlackFriday.module.css";

function copyToClipboard() {
  const el = document.createElement("textarea");
  el.value = "BLACKFRIDAY";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  alert("BLACKFRIDAY code copied to clipboard");
}

// get timer that how many time left to be 25 november 2023
function getTimeRemaining(endtime) {
  const total = Date.parse(endtime) - Date.parse(new Date());
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  };
}

const BlackFriday = () => {
  // set timer
  const deadline = "November 27 2023 00:00:00 GMT+0530";
  const [timeLeft, setTimeLeft] = useState(getTimeRemaining(deadline));

  timeLeft.total > 0 &&
    setTimeout(() => {
      setTimeLeft(getTimeRemaining(deadline));
    }, 1000);

  return (
    timeLeft.total > 0 && (
      <div
        class="jumbotron w-full text-center cursor-pointer backdrop-blur-3xl bg-[url('https://img.freepik.com/premium-photo/halloween-scary-pumpkin-fire-black-background-isolate-ai-generated_868611-1916.jpg')] object-cover	"
        onClick={timeLeft.total > 0 && copyToClipboard}
      >
        <div class="container mx-auto">
          <h1 className="text-2xl lg:text-4xl p-10 animate-pulse  text-gray-300 font-bold">
            ₿Мега-предложение черной пятницы{" "}
            {timeLeft.total > 0 && (
              <p className="text-xs font-semibold text-gray-200 p-2 ">
                {" "}
                Нажмите здесь, чтобы скопировать код{" "}
              </p>
            )}
          </h1>
          <h2 className="text-xl font-bold p-1">ПРЕДЛОЖЕНИЯ ЗАКАНЧИВАЮТСЯ</h2>
          {timeLeft.total > 0 && (
            <h2 className="text-xl lg:text-3xl font-mono backdrop-blur-3xl py-1 ">
              {timeLeft?.days} days {timeLeft?.hours} hours {timeLeft?.minutes}{" "}
              minutes {timeLeft?.seconds} left
            </h2>
          )}
          <marquee
            className="text-xl lg:text-3xl text-center border-2 shadow-xl p-3  text-gray-300 font-bold bg-gray-900"
            behavior="scroll"
            direction="middle"
            scrollamount="10"
          >
            Скидка 40% на все товары - используйте код купона{" "}
            <span className="text-red-500">ЧЕРНАЯПЯТНИЦА</span>
          </marquee>
        </div>

        <hr className="border-1 shadow-inner mt-6 border-gray-300" />
      </div>
    )
  );
};

export default BlackFriday;
