import React, { useState } from "react";
import { PriceCard } from "../price/PriceCard";
import { PriceCardNotAvailable } from "../price/PriceCard-notavailable,";

export const Shop = () => {
  const [showModal, setShowModal] = React.useState(false);
  const [showModal2, setShowModal2] = React.useState(false);
  const [termsAccepted, setTermAccepted] = React.useState(false);

  return (
    <div className="container mx-auto px-5 flex flex-col items-center my-16">
      <p className="text-3xl lg:w-1/2 text-center font-bold">Наши продукты</p>
      <p className="mt-2 mb-3">
        Чтобы получить бесплатную демонстрационную вспышку, отправьте запрос.
      </p>
      <button
        onClick={() => {
          setShowModal(true);
        }}
        className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
      >
        Запросить демо
      </button>

      <div
        class={`${
          showModal ? "flex" : "hidden"
        } bg-[#0000003c] overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center`}
      >
        <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div class="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
              <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                Условия и положения
              </h3>
              <button
                onClick={() => {
                  setShowModal(false);
                }}
                type="button"
                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-toggle="defaultModal"
              >
                <svg
                  aria-hidden="true"
                  class="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span class="sr-only">Закрыть модальное окно</span>
              </button>
            </div>

            <div class="p-6 space-y-6">
              <div className="max-h-[200px] overflow-auto border-2 p-3">
                <p className="terms-text">
                  В качестве условия использования вы обязуетесь не использовать
                  демонстрационные услуги HydraFlasher в любых целях, которые
                  являются незаконными или запрещены настоящими Условиями.{" "}
                  <br />
                  В качестве примера, а не ограничения, вы соглашаетесь не
                  использовать Услуги: <br />
                  Для получения демонстрационной флеш-ролика вам необходимо
                  отправить запрос администратору сервера.
                  <br /> Мы предоставим бесплатную лицензию каждому новому
                  пользователю. По этой бесплатной лицензии пользователь может
                  бесплатно прошить 0,0001 BTC в своем собственном кошельке
                  (пользователь может прошить свой собственный). <br />
                  Представитель Hydra Flasher никогда не попросит Anydesk или
                  Team Viewer предоставить демонстрационную вспышку.
                  <br />
                  Пользователь может прошить один раз с этой демонстрационной
                  лицензией. После прошивки демо-версии лицензия автоматически
                  деактивируется. <br />
                  Для продолжения или активации пакетной лицензии пользователь
                  должен пройти для получения платной лицензии из магазина.
                  <br />
                  Платная лицензия будет доступна в нашем разделе Магазин. Наш
                  старый магазин Sellix закрыт.
                </p>
              </div>

              <div class="flex items-center mb-4">
                <input
                  onChange={(e) => {
                    setTermAccepted(e.target.checked);
                  }}
                  id="default-checkbox"
                  type="checkbox"
                  value=""
                  class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />

                <label
                  for="default-checkbox"
                  class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Я согласен с условиями.
                </label>

                <br />
              </div>

              <button
                onClick={() => {
                  setShowModal(false);
                  setShowModal2(true);
                }}
                className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
                disabled={termsAccepted ? false : true}
              >
                Запросить демо{" "}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class={`${
          showModal2 ? "flex" : "hidden"
        } bg-[#0000003c] overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full justify-center items-center`}
      >
        <div class="relative p-4 w-full max-w-2xl h-full md:h-auto">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div class="flex justify-between items-start p-4 rounded-t border-b dark:border-gray-600">
              <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                Отправить запрос
              </h3>
              <button
                onClick={() => {
                  setShowModal2(false);
                }}
                type="button"
                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-toggle="defaultModal"
              >
                <svg
                  aria-hidden="true"
                  class="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span class="sr-only">Закрыть модальное окно</span>
              </button>
            </div>

            <div class="p-6 space-y-6">
              <div>
                <label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Ваш адрес электронной почты{" "}
                </label>
                <input
                  type="email"
                  id="email"
                  class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light"
                  placeholder="name@flowbite.com"
                  required
                />
              </div>

              <p>Запросить демо-версию</p>
              <p>
                <a
                  href="mailto:hydrabtcflasher@gmail.com"
                  className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
                >
                  Email
                </a>{" "}
                or{" "}
                <a
                  target={"_blank"}
                  href="https://t.me/hydraflasher

"
                  className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
                >
                  Telegram
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-between gap-6 mt-16 lg:flex-row flex-col">
        <PriceCard
          key={2}
          details={{
            title: "Hydra Flasher",
            header: "Лучший вариант для личного пользования на 2 месяца.",
            price: "950",
            lifetime: false,
            infos: [
              "Простая настройка",
              "Никаких настроек или скрытых платежей",
              "Дневной лимит 5 биткойнов",
              "Торговля и трансфертный фонд",
              "Поддержка клиентов: 2 месяца",
              "Бесплатные обновления: 2 месяца",
              "1 лицензионный ключ для одного устройства",
            ],
            product_type: "HYDRAFLASHER",
          }}
        />
        <PriceCard
          key={2}
          details={{
            title: "Hydra Flasher Premium",
            header:
              "Лучший вариант для профессионального использования со сроком действия 4 месяца.",
            price: "1700",
            lifetime: false,
            infos: [
              "Простая настройка",
              "Никаких настроек или скрытых платежей",
              "Дневной лимит 10 биткойнов",
              "Торговля и трансфертный фонд",
              "Премиум поддержка: 4 месяца",
              "Бесплатные обновления: 4 месяца",
              "1 лицензионный ключ для одного устройства",
            ],
            product_type: "HYDRAFLASHER_PREMIUM",
          }}
        />
        <PriceCard
          key={2}
          details={{
            title: "Hydra Script (API Access)",
            header: "На 10 дней",
            price: "25,000",
            lifetime: false,
            infos: [
              "Настройка (для Debian Linux и Windows)",
              "Интерфейс командной строки",
              "Дневной лимит Flash: «Без ограничений»",
              "Торговля и трансфертный фонд",
              "Бесплатная настройка в первый раз",
              "Бесплатные обновления: только на время подписки.",
              "1 лицензионный ключ для одного устройства",
            ],
            product_type: "HYDRAFLASHER_API_ACCESS",
          }}
        />
        <PriceCardNotAvailable
          key={2}
          details={{
            title: "Bullet Proof RDP",
            header: "Лучший вариант для личного пользования на 2 месяца.",
            pricePreffix: "",
            price: "250",
            lifetime: false,
            infos: [
              "Предварительно настроено",
              "Никаких настроек или скрытых платежей",
              "Доступ администратора",
              "Премиум поддержка: 2 месяца",
              "6 ядер 16 ГБ ОЗУ",
              "1 лицензионный ключ для одного устройства",
              "Сервер в Сингапуре",
            ],
            product_type: "BULLET_PRROF_RDP",
          }}
        />
      </div>
    </div>
  );
};
