import React from "react";

const Download = () => {
  const [DownloadData, setDownloadData] = React.useState({});
  const getreviewssData = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    fetch("/Download-data.json", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setDownloadData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      // console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  }
  function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        // console.log("Async: Copying to clipboard was successful!");
        alert("Хэш скопирован успешно");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  React.useEffect(() => {
    getreviewssData();
  }, []);
  return (
    <div className="container mx-auto mt-5 px-5 flex flex-col items-center mb-5">
      <p className="text-3xl lg:w-1/2 text-center font-bold">Скачать</p>
      <div className="grid grid-cols-1 justify-between gap-6 mt-16 flex-col lg:grid-cols-3">
        <div>
          <div class="lg:justify-between flex flex-col p-6 mx-auto max-w-lg text-center rounded-lg border  shadow border-gray-600 xl:p-8 bg-gray-800 text-white lg:min-h-[500px]">
            <h3 class="mb-4 text-2xl font-semibold">HydraFlasher</h3>
            <p class="font-light sm:text-lg text-gray-400 mb-3">
              Hydra flasher — это мощный инструмент для прошивки биткоинов. Он
              поможет вам прошить ваш биткоин-кошелек до 100 биткоинов в день.
            </p>

            <div className="grid grid-cols-1 gap-4">
              <a
                className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
                href={DownloadData.hydraflasher_demo}
                target={"_blank"}
              >
                Загрузить демо
              </a>
              <a
                className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
                href={DownloadData.hydraflasher}
                target={"_blank"}
              >
                Скачать Премиум
              </a>
            </div>
          </div>
        </div>

        <div>
          <div class="lg:justify-between flex flex-col p-6 mx-auto max-w-lg text-center rounded-lg border  shadow border-gray-600 xl:p-8 bg-gray-800 text-white lg:min-h-[500px]">
            <h3 class="mb-4 text-2xl font-semibold">HydraFlasher Mobile</h3>
            <p class="font-light sm:text-lg text-gray-400 mb-3">
              Мобильное приложение Hydra Flasher — это мощный инструмент для
              прошивки биткоинов. Он поможет вам прошить ваш биткоин-кошелек до
              100 биткоинов в день с вашего мобильного устройства. Минимальная
              версия Android должна быть 5.0.
            </p>

            <a
              className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
              href={DownloadData.hydraflasher_mobile_demo}
              target={"_blank"}
            >
              Загрузить демо
            </a>

            <a
              className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
              href={DownloadData.hydraflasher_mobile}
              target={"_blank"}
            >
              Скачать Премиум
            </a>

            <a
              className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
              href={DownloadData.hydraflasher_mobile_antminer}
              target={"_blank"}
            >
              Скачать AntMiner
            </a>
          </div>
        </div>

        <div>
          <div class="lg:justify-between flex flex-col p-6 mx-auto max-w-lg text-center rounded-lg border  shadow border-gray-600 xl:p-8 bg-gray-800 text-white lg:min-h-[500px]">
            <h3 class="mb-4 text-2xl font-semibold">Bitcoin Core 5.0.1-V9</h3>
            <p class="font-light sm:text-lg text-gray-400 mb-3">
              Bitcoin Core запрограммирован на то, чтобы решать, какая цепочка
              блоков содержит действительные транзакции. Пользователи Bitcoin
              Core принимают только транзакции для этой цепочки блоков, делая ее
              цепочкой блоков Bitcoin, которую все остальные хотят использовать.
            </p>

            <a
              className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
              href={DownloadData.bitcoin_core}
              target={"_blank"}
            >
              Скачать
            </a>
          </div>
        </div>

        <div>
          <div class="lg:justify-between flex flex-col p-6 mx-auto max-w-lg text-center rounded-lg border  shadow border-gray-600 xl:p-8 bg-gray-800 text-white lg:min-h-[500px]">
            <h3 class="mb-4 text-2xl font-semibold">Хэш</h3>
            <p class="font-light sm:text-lg text-gray-400 mb-3">
              <label
                for="message"
                class="font-light sm:text-lg text-gray-400 mb-3"
              >
                Хэш-код поможет вам подключить Flasher к нашей сети.
              </label>
              <div
                id="message"
                rows="4"
                class="break-all block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Your message..."
                readOnly
              >
                {DownloadData.copy_hash}
              </div>
            </p>

            <a
              onClick={(e) => {
                e.preventDefault();
                copyTextToClipboard(DownloadData.copy_hash);
              }}
              className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
              href="#"
            >
              Копировать хэш
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
