import axios from "axios";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { registerRoute, getPublicIP } from "../../utils/Routes";
import { useNavigate } from "react-router-dom";

import SlideButton from "react-slide-button";
const Register = () => {
  const [Captcha, setCaptcha] = React.useState(false);

  const [passwordErr, setPasswordErr] = React.useState(false);
  const [passwordMatchErr, setPasswordMatchErr] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [errorMsg, seterrorMsg] = React.useState("");
  const navigate = useNavigate();
  const checkuser = () => {
    let user = localStorage.getItem("user");
    if (user != null) {
      navigate("/dashboard");
    }
  };
  React.useEffect(() => {
    checkuser();
  }, []);
  const [userInfo, setUserInfo] = React.useState({
    fname: "",
    lname: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (userInfo.password.length < 8 && userInfo.password.length > 0) {
      setPasswordErr("Password must be at least 8 characters long");
    } else {
      setPasswordErr(false);
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo.password !== userInfo.confirmPassword) {
      setPasswordMatchErr("Passwords do not match");
    } else {
      setPasswordMatchErr(false);
    }
  }, [userInfo]);

  const handleChange = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (passwordErr || passwordMatchErr) {
      return false;
    } else {
      // axios.post(registerRoute, userInfo).then((res) => {
      // 	console.log(res.data)
      // })
      // const userInfo = {
      // 	email: e.target.email.value,
      // 	password: e.target.password.value,
      // }
      let ip = await getPublicIP();
      userInfo.initial_ip = ip;
      userInfo.current_ip = ip;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(userInfo),
      };

      fetch(registerRoute, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log("h: ", data);
          if (data.status == 201) {
            let userdata = data.data;
            localStorage.setItem("user", JSON.stringify(userdata));
            // console.log(data.data);
            navigate("/dashboard");
          } else {
            setError(true);
            seterrorMsg(data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="container mx-auto px-5 flex justify-between w-[calc(100%)]  py-4 flex-col lg:flex-row">
      <div className="lg:w-1/2 flex justify-center items-center h-full flex-col">
        <img
          src="https://hydraflasher.com/logo512.png"
          className="w-[200px] animate-spin py-6"
        />
        <h1 className="text-4xl font-extrabold text-white text-center ">
          Добро пожаловать в{" "}
          <span className="text-primary"> Hydra Flasher</span>
        </h1>
        {/* <p className="mt-4">
					Lorem ipsum, dolor sit amet consectetur adipisicing elit.
					Officiis, saepe!
				</p> */}
      </div>
      <div className="lg:w-1/2 w-full">
        <form
          onSubmit={handleFormSubmit}
          className="flex flex-col items-center justify-center h-full"
        >
          <h1 className="text-4xl font-extrabold text-white text-center">
            Зарегистрироваться
          </h1>
          <input
            type="text"
            placeholder="Имя здесь..."
            name="fname"
            required
            class="input input-bordered input-primary w-[calc(90%)] max-w-xs lg:mt-6 mt-3"
            onChange={(e) => handleChange(e)}
          />
          <input
            type="text"
            placeholder="Фамилия здесь..."
            name="lname"
            required
            class="input input-bordered input-primary w-[calc(90%)] max-w-xs lg:mt-6 mt-3"
            onChange={(e) => handleChange(e)}
          />
          <input
            type="email"
            placeholder="Напишите нам..."
            name="email"
            required
            class="input input-bordered input-primary w-[calc(90%)] max-w-xs lg:mt-6 mt-3"
            onChange={(e) => handleChange(e)}
          />
          <input
            type="password"
            name="password"
            placeholder="Пароль здесь..."
            required
            class="input input-bordered input-primary w-[calc(90%)] max-w-xs lg:mt-6 mt-3"
            onChange={(e) => handleChange(e)}
          />
          <p className="text-error text-sm">{passwordErr}</p>
          <input
            type="password"
            name="confirmPassword"
            placeholder="Подтвердите пароль здесь..."
            required
            class="input input-bordered input-primary w-[calc(90%)] max-w-xs lg:mt-6 mt-3"
            onChange={(e) => handleChange(e)}
          />
          <p className="text-error text-sm">{passwordMatchErr}</p>
          <div className="w-[calc(70%)] max-w-xs mt-6 rounded-lg my-2  mx-auto font-serif font-bold bg-slate-300">
            {" "}
            <SlideButton
              mainText="Slide Me"
              overlayText="H Y D R A"
              onSlideDone={function () {
                setCaptcha(true);
              }}
            />
          </div>
          <input
            disabled={
              passwordErr ||
              Captcha == false ||
              passwordMatchErr ||
              userInfo.fname.length === 0 ||
              userInfo.lname.length === 0 ||
              userInfo.email.length === 0 ||
              userInfo.password.length === 0 ||
              userInfo.confirmPassword.length === 0
            }
            type="submit"
            value="Register"
            class="input input-bordered input-primary w-[calc(90%)] max-w-xs lg:mt-6 mt-3"
          />
          <Link
            className="text-sm mt-2 text-secondary hover:underline"
            to="/login"
          >
            Уже зарегистрированы?{" "}
          </Link>
        </form>
      </div>
    </div>
  );
};

export default Register;
