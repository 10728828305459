import React from "react";

export const PriceCardNotAvailable = ({ details }) => {
  return (
    <div>
      <div class="flex flex-col p-6 mx-auto max-w-lg text-center rounded-lg border  shadow border-gray-600 xl:p-8 bg-gray-800 text-white">
        <h3 class="mb-4 text-2xl font-semibold">{details?.title}</h3>
        <p class="font-light sm:text-lg text-gray-400">{details.header}</p>
        <div class="flex justify-center items-baseline my-8">
          <span className="text-xl font-bold">{details.pricePreffix}</span>
          <span class="mr-2 text-5xl font-extrabold">${details.price}</span>
          <span class=" text-gray-400">
            {details.lifetime ? " / lifetime" : ""}
          </span>
        </div>

        <ul role="list" class="mb-8 space-y-4 text-left">
          {details.infos.map((data, index) =>
            index % 2 === 0 ? (
              <li key={index} class="flex items-center space-x-3">
                <svg
                  class="flex-shrink-0 w-5 h-5text-green-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span>{data}</span>
              </li>
            ) : (
              <li class="flex items-center space-x-3">
                <svg
                  class="flex-shrink-0 w-5 h-5 text-green-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span>{data}</span>
              </li>
            )
          )}
        </ul>

        <a
          onClick={(e) => {
            e.preventDefault();
          }}
          className="buy-with-crypto bg-[#ec3b3b]  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
          href={details.checkoutUrl}
        >
          Вскоре
        </a>
      </div>
    </div>
  );
};
