import {
  FaDiscord,
  FaFacebook,
  FaTelegramPlane,
  FaTwitter,
} from "react-icons/fa";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="bg-accent/75 backdrop-blur-2xl">
      <div className="container mx-auto px-5 flex flex-col lg:flex-row lg:justify-between py-12">
        <div className="flex flex-col items-start lg:w-1/3">
          <p>
            <span className="text-3xl common font-extrabold text-primary">
              Hydra Flasher{" "}
            </span>
            &copy; {new Date().getFullYear()}
          </p>
          <p className="text-sm text-gray-400 mt-4">
            Программное обеспечение Hydra Bitcoin Flasher — это последняя версия
            программного обеспечения Bitcoin Flash.
          </p>
          <div className="mt-5 flex items-center justify-start">
            <a href="https://t.me/hydraflasher" target={"_blank"}>
              <FaTelegramPlane className="mr-4 text-xl text-primary" />
            </a>
            <a href="https://twitter.com/HydraFlasher" target={"_blank"}>
              <FaTwitter className="mr-4 text-xl text-primary" />
            </a>
          </div>
        </div>
        <div className="flex flex-col items-start lg:w-1/3 mt-16 lg:mt-0">
          <h3 className="text-xl text-primary font-extrabold">
            Связаться с нами
          </h3>
          <p className="text-sm text-gray-400 mt-4">
            <a href="mailto:hydrabtcflasher@gmail.com">
              Электронная почта: hydrabtcflasher@gmail.com
            </a>
          </p>
          <p className="text-sm text-gray-400 mt-4">
            Телеграмма: https://t.me/hydraflasher
          </p>
          <p className="text-sm text-gray-400 mt-4">
            Канал: https://t.me/hydraflasher_com
          </p>
          <p className="text-sm text-gray-400 mt-4">
            Твиттер: https://twitter.com/HydraFlasher
          </p>
        </div>
      </div>
    </footer>
  );
};
