import React from "react";
import { useStep } from "../../contexts/stepContexts";

const PackageDescription = ({ details, fee = false }) => {
  console.log(details);
  const { priceAfterVoucher } = useStep();
  return (
    <div className="p-6">
      <div className="flex justify-between">
        <p></p>
        <p className="text-end" style={{ color: "#3CB95D" }}>
          <span className="font-semibold">Total</span> ${priceAfterVoucher} USD
        </p>
      </div>

      <div className="flex mt-8">
        <div className="flex-1">
          <div className="flex flex-col justify-start space-y-2 text-gray-900">
            <p className="font-semibold flex">
              {details?.title || details?.product_name}
            </p>
            <div className="flex flex-col text-left">
              <p className="tracking-tighter text-sm">{details?.header}</p>
              {details?.infos?.map((info, index) => {
                return (
                  <div className="flex gap-2" key={index}>
                    - <p className="text-sm">{info}</p>
                  </div>
                );
              })}
            </div>
            <p className="flex text-sm">Период проверки: 3 дня</p>
            <p className="flex text-sm">
              Плательщик комиссии за эскроу: 100% Покупатель
            </p>
          </div>
        </div>
        <div className="mt-4 sm:mt-0 text-gray-900 space-y-2 flex flex-col items-end">
          <p className="flex text-sm">${priceAfterVoucher} USD</p>
          <p className="text-sm">
            <span className="font-semibold text-sm">Escrow Fee</span> $32.00 USD
          </p>
          <p className="text-sm">
            <span className="font-semibold text-sm">
              Комиссия за обработку платежа
            </span>{" "}
            $27.00 USD
          </p>
        </div>
      </div>
    </div>
  );
};

export default PackageDescription;
