import React from "react";
import { baseUrl } from "../utils/Routes";
import ReportDesc from "./ReportDesc";

export default function ReportScamCard({
  date,
  title,
  description,
  images,
  userMail,
  image1,
  image2,
  image3,
  image4,
  isUpdated,
}) {
  const startsWithHttpOrHttps = (str) => {
    return str.startsWith("http://") || str.startsWith("https://");
  };
  return (
    <div class="my-4 divide-y-2 divide-[#0d957c] shadow-2xl ">
      <div class="px-[calc(1%)] -my-1 flex justify-between ">
        <span class="mt-1 text-yellow-400 bg-yellow-50 font-semibold   w-[150px] rounded hover:animate-bounce text-left text-md px-4">
          {new Date(date).toLocaleDateString("en-GB")}
        </span>
        <span class="mt-1 text-indigo-400 bg-indigo-50 font-semibold   w-[150px] hover:underline  rounded text-left text-md px-2">
          <small>Reported By</small> <br />
          {isUpdated
            ? "ADMIN"
            : userMail
            ? userMail.split("@")[0]
            : "anonymous"}
        </span>
      </div>

      <div
        class="py-8 flex flex-wrap md:flex-nowrap border border-indigo-200 backdrop-blur-sm rounded   px-4 
          "
      >
        <div className="lg:flex block items-stretch ">
          <h2 class="lg:text-2xl text-xl font-medium text-yellow-400 title-font mb-2 block md:hidden">
            {title}
          </h2>

          <img
            src={
              startsWithHttpOrHttps(image1)
                ? image1
                : baseUrl + "images/" + images[0].filename
            }
            alt={title}
            // lg:w-1/3 w-full
            className=" w-auto max-h-[400px]   rounded-2xl "
          />
          <div className="block md:hidden">
            <div className="flex justify-around items-center lg:px-[calc(5%)] lg:w-3/4   ">
              {!isUpdated &&
                images.length >= 2 &&
                images.slice(1, 4).map((x, index) => {
                  let src = "";
                  if (index === 0) {
                    src = startsWithHttpOrHttps(eval("image" + (index + 1)))
                      ? eval("image" + (index + 1))
                      : baseUrl + "images/" + x.filename;
                  } else if (index === 1) {
                    src = startsWithHttpOrHttps(eval("image" + 3))
                      ? eval("image" + 2)
                      : baseUrl + "images/" + x.filename;
                  } else if (index === 2) {
                    src = startsWithHttpOrHttps(eval("image" + 4))
                      ? eval("image" + 4)
                      : baseUrl + "images/" + x.filename;
                  }
                  return (
                    <img
                      key={index}
                      alt={x.filename}
                      title={"image" + (index + 1)}
                      src={src}
                      className="w-[140px] h-[140px] m-1 mt-2   rounded-2xl "
                    />
                  );
                })}
              {isUpdated && (
                <div className="block md:hidden">
                  <div className="flex justify-around items-center lg:px-[calc(5%)] lg:w-3/4 ">
                    {[image2, image3, image4].map((image, index) => (
                      <>
                        {image && (
                          <img
                            key={index}
                            src={image}
                            lazy="true"
                            className="w-[140px] h-[140px] m-1 mt-2  bg rounded-2xl "
                            placeholder="placeholder.png"
                          />
                        )}
                      </>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div class="md:flex-grow m-[calc(1%)]">
            <h2 class="lg:text-2xl text-xl font-medium text-yellow-400 title-font mb-2 hidden md:block">
              {title}
            </h2>

            {/*     SingleResult[0]?.WorkStation ?  SingleResult[0]?.WorkStation.split("\n").map(function(item, idx) {
             */}
            <div className="leading-relaxed">
              <ReportDesc description={description} />
            </div>
            <br />
            <div className=" xl:overflow-clip md:overflow-x-scroll  ">
              {images != [] && images.length >= 2 && (
                <div className="lg:flex justify-between items-end">
                  {" "}
                  <div className="md:flex justify-around items-center lg:px-[calc(5%)] lg:full hidden ">
                    {images.length >= 2 &&
                      images.slice(1, 4).map((x, index) => {
                        let src = "";
                        if (index === 0) {
                          src = startsWithHttpOrHttps(eval("image" + 2))
                            ? eval("image" + 2)
                            : baseUrl + "images/" + x.filename;
                        } else if (index === 1) {
                          src = startsWithHttpOrHttps(eval("image" + 3))
                            ? eval("image" + 3)
                            : baseUrl + "images/" + x.filename;
                        } else if (index === 2) {
                          src = startsWithHttpOrHttps(eval("image" + 4))
                            ? eval("image" + 4)
                            : baseUrl + "images/" + x.filename;
                        }
                        return (
                          <img
                            alt={x.filename}
                            // title={"image" + (index + 1)}
                            src={src}
                            className="w-[200px] ml-4 h-[200px]  rounded-2xl "
                          />
                        );
                      })}
                  </div>
                </div>
              )}
              {isUpdated && (
                <div className="lg:flex justify-between items-end">
                  <div className="md:flex justify-around items-center lg:px-[calc(5%)] lg:full hidden ">
                    {[image2, image3, image4].map((image, index) => (
                      <>
                        {image && (
                          <img
                            key={index}
                            src={image}
                            lazy="true"
                            className="w-[200px] ml-2 h-[200px] rounded-2xl"
                          />
                        )}
                      </>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* More three Images if Exist */}
      </div>
    </div>
  );
}
