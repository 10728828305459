import { useState } from "react";
import rdpImage from "../../assets/images/services/rdp.png";
import standardImage from "../../assets/images/services/standard.png";
import premiumImage from "../../assets/images/services/premium.png";

export const Services = () => {
  const [selected, setSelected] = useState("Hydra Flasher Standard");

  return (
    <div className="container mx-auto px-5 flex flex-col items-center my-16">
      <p className="text-3xl lg:w-1/2 text-center font-bold w-full">
        Наши услуги
      </p>
      <div className="flex gap-6 mt-16 w-full bg-gray-800 py-5 px-3 flex-col lg:flex-row">
        <div className="lg:w-1/4 w-full">
          <ul className="flex flex-col">
            <li
              onClick={() => setSelected("Hydra Flasher Standard")}
              className="bg-primary/50 font-bold text-white p-2 my-3 mx-2 rounded-md cursor-pointer"
            >
              Hydra Flasher Standard
            </li>
            <li
              onClick={() => setSelected("Hydra Flasher Premium")}
              className="bg-primary/50 font-bold text-white p-2 my-3 mx-2 rounded-md cursor-pointer"
            >
              Hydra Flasher Premium
            </li>
            <li
              onClick={() => setSelected("Hydra Script (API)")}
              className="bg-primary/50 font-bold text-white p-2 my-3 mx-2 rounded-md cursor-pointer"
            >
              Hydra Script (API)
            </li>
            <li
              onClick={() => setSelected("Hydra RDP")}
              className="bg-primary/50 font-bold text-white p-2 my-3 mx-2 rounded-md cursor-pointer"
            >
              Hydra RDP
            </li>
          </ul>
        </div>
        <div className="lg:w-3/4 flex flex-col items-center w-full">
          <p className="text-xl font-bold">{selected}</p>
          <div className=" flex justify-between mt-5 px-3 w-full lg:flex-row flex-col">
            <div className="lg:w-1/3 flex justify-center items-center w-full">
              {selected === "Hydra Flasher Standard" && (
                <img src={standardImage} alt="" className="max-w-[200px]" />
              )}
              {selected === "Hydra Flasher Premium" && (
                <img src={premiumImage} alt="" className="max-w-[200px]" />
              )}
              {selected === "Hydra RDP" && (
                <img src={rdpImage} alt="" className="max-w-[200px]" />
              )}
              {selected === "Hydra Script (API)" && (
                <img src={premiumImage} alt="" className="max-w-[200px]" />
              )}
            </div>
            <div className="lg:w-3/4 w-full">
              <p>
                {selected === "Hydra Flasher Standard" && (
                  <div>
                    <p>
                      1. Наш Flasher работает практически на всех кошельках.{" "}
                    </p>
                    <p>
                      2. Вы можете перевести мгновенные средства на любой
                      кошелек, например, Binance, Coinbase, Blockchain, Atomic и
                      т. д.
                    </p>
                    <p>3. Никаких настроек и скрытых платежей.</p>
                    <p>
                      4. Операторы HydraFlasher никогда не будут запрашивать
                      какие-либо персональные данные.
                    </p>
                    <p>
                      5. Срок действия мгновенных средств составляет минимум 21
                      день.
                    </p>
                    <p>
                      6. Если у вас возникнут какие-либо проблемы, свяжитесь с
                      нами.
                    </p>
                    <p>
                      7. Наш оператор службы поддержки клиентов всегда готов вам
                      помочь.
                      <p>
                        8. Telegram:{" "}
                        <a href="https://t.me/hydraflasher">
                          {" "}
                          https://t.me/hydraflasher
                        </a>
                      </p>{" "}
                    </p>
                  </div>
                )}
                {selected === "Hydra Flasher Premium" && (
                  <div>
                    <p>
                      1. Наш Flasher работает практически на всех кошельках.{" "}
                    </p>
                    <p>
                      2. Вы можете перевести мгновенные средства на любой
                      кошелек, например, Binance, Coinbase, Blockchain, Atomic и
                      т. д.
                    </p>
                    <p>3. Никаких настроек и скрытых платежей.</p>
                    <p>
                      4. Операторы HydraFlasher никогда не будут запрашивать
                      какие-либо персональные данные.
                    </p>
                    <p>
                      5. Срок действия мгновенных средств составляет минимум 21
                      день
                    </p>
                    <p>
                      6. Если у вас возникнут какие-либо проблемы, свяжитесь с
                      нами.
                    </p>
                    <p>
                      7. Наш оператор службы поддержки клиентов всегда готов вам
                      помочь.
                      <p>
                        8. Telegram:{" "}
                        <a
                          className="font-bold hover:underline"
                          href="https://t.me/hydraflasher"
                        >
                          {" "}
                          https://t.me/hydraflasher
                        </a>
                      </p>{" "}
                    </p>
                  </div>
                )}
                {selected === "Hydra RDP" &&
                  `Протокол удаленного рабочего стола (RDP) — это защищенный сетевой протокол связи, разработанный корпорацией Microsoft. Он позволяет сетевым администраторам удаленно диагностировать проблемы, с которыми сталкиваются отдельные пользователи, и предоставляет пользователям удаленный доступ к их физическим рабочим компьютерам. Им можно управлять с помощью любого физического компьютера или любого устройства Android `}

                {selected === "Hydra Script (API)" && (
                  <div>
                    <p>
                      1. Настройка (для Debian Linux и Windows) Интерфейс
                      командной строки
                    </p>
                    <p>
                      2. Дневной лимит Flash: торговля и перевод «без
                      ограничений»{" "}
                    </p>
                    <p>
                      {" "}
                      3. Объект. Бесплатная настройка в первый раз Бесплатные
                      обновления: Только на время подписки.
                    </p>
                  </div>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
