import React, { useEffect, useState } from "react";
import { useStep } from "../../../contexts/stepContexts";
import { z } from "zod";

// Define the validation schema
const signUpSchema = z.object({
  email: z.string().email("Неверный адрес электронной почты"),
  password: z
    .string()
    .min(7, "Пароль должен содержать не менее 7 символов.")
    .regex(/[A-Z]/, "Пароль должен содержать хотя бы одну заглавную букву")
    .regex(/[a-z]/, "Пароль должен содержать хотя бы одну строчную букву.")
    .regex(
      /[0-9]/,
      "Пароль должен содержать хотя бы одну цифру или специальный символ."
    ),
});

const SignUp = () => {
  const { changeStep, setUrl } = useStep();

  useEffect(() => {
    setUrl("http://127.0.0.1:5500/escrow/payment");
  }, []);
  const [formValues, setFormValues] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({ email: "", password: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const result = signUpSchema.safeParse(formValues);
    if (result.success) {
      // Form is valid, proceed with form submission logic
      console.log("Form is valid", formValues);
    } else {
      // Form is invalid, update the errors state
      const fieldErrors = result.error.format();
      setErrors({
        email: fieldErrors.email?._errors[0] || "",
        password: fieldErrors.password?._errors[0] || "",
      });
    }
  };

  return (
    <div className="bg-white pt-6 min-lg:h-[900px] lg:w-[800px] sm:w-[500px] md:w-[700px] mx-auto p-4">
      <p className="text-gray-800 mb-2 md:mb-4">
        Пожалуйста, посетите официальный сайт Escrow, чтобы создать новую
        учетную запись. После того, как ваша учетная запись будет настроена,
        пожалуйста, вернитесь на наш сайт, чтобы войти в систему и продолжить
        процесс.
      </p>
      <button
        onClick={() => changeStep("login")}
        className="text-xs font-bold underline underline-offset-4 text-[#3CB95D] border-[#3CB95D]"
      >
        ВОЙТИ В ESCROW.COM
      </button>
    </div>
  );
};

export default SignUp;

const PasswordFeature = ({ text }) => {
  return (
    <li className="flex items-center gap-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        class="icon injected-svg signupForm-suggestion-icon"
        width="20"
        height="20"
        viewBox="0 0 25 25"
        data-src="../../../../build/images/global/icons/tick.svg"
        className="fill-gray-200 font-thin w-3 h-3"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.7,4l-15,15c0,0,0,0,0,0c-0.1,0.1-0.1,0.2-0.2,0.3c-0.9,0.9-2.4,0.9-3.2,0      l-5.5-4.7c-0.9-0.9-0.9-2.4,0-3.2c0.9-0.9,2.4-0.9,3.2,0l3.7,3.2L21.4,0.7c0.9-0.9,2.4-0.9,3.3,0C25.6,1.6,25.6,3.1,24.7,4z"
        ></path>
      </svg>
      <p className="text-sm text-gray-500"> {text}</p>
    </li>
  );
};
