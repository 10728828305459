import { PriceCard } from "./PriceCard";
import { PriceCardNotAvailable } from "./PriceCard-notavailable,";

export const Price = () => {
  return (
    <div className="container mx-auto px-5 flex flex-col items-center mb-5">
      <p className="text-2xl lg:w-1/2 text-center font-bold">Наши продукты</p>
      <div className="flex justify-between gap-6 mt-16 flex-col lg:flex-row">
        <PriceCard
          key={2}
          details={{
            title: "Hydra Flasher",
            header: "Лучший вариант для личного пользования на 2 месяца.",
            price: "950",
            lifetime: false,
            infos: [
              "Простая настройка",
              "Никаких настроек или скрытых платежей",
              "Дневной лимит 5 биткойнов",
              "Торговля и трансфертный фонд",
              "Поддержка клиентов: 2 месяца",
              "Бесплатные обновления: 2 месяца",
              "1 лицензионный ключ для одного устройства",
            ],
            product_type: "HYDRAFLASHER",
          }}
        />
        <PriceCard
          key={2}
          details={{
            title: "Hydra Flasher Premium",
            header:
              "Лучший вариант для профессионального использования со сроком действия 4 месяца.",
            price: "1700",
            lifetime: false,
            infos: [
              "Простая настройка",
              "Никаких настроек или скрытых платежей",
              "Дневной лимит 10 биткойнов",
              "Торговля и трансфертный фонд",
              "Премиум поддержка: 4 месяца",
              "Бесплатные обновления: 4 месяца",
              "1 лицензионный ключ для одного устройства",
            ],
            product_type: "HYDRAFLASHER_PREMIUM",
          }}
        />
        <PriceCard
          key={2}
          details={{
            title: "Hydra Script (API Access)",
            header: "На 10 дней",
            price: "25,000",
            lifetime: false,
            infos: [
              "Настройка (для Debian Linux и Windows)",
              "Интерфейс командной строки",
              "Дневной лимит Flash: «Без ограничений»",
              "Торговля и трансфертный фонд",
              "Бесплатная настройка в первый раз",
              "Бесплатные обновления: только на время подписки.",
              "1 лицензионный ключ для одного устройства",
            ],
            product_type: "HYDRAFLASHER_API_ACCESS",
          }}
        />
        <PriceCardNotAvailable
          key={2}
          details={{
            title: "Bullet Proof RDP",
            header: "Лучший вариант для личного пользования на 2 месяца.",
            pricePreffix: "",
            price: "250",
            lifetime: false,
            infos: [
              "Предварительно настроено",
              "Никаких настроек или скрытых платежей",
              "Доступ администратора",
              "Премиум поддержка: 2 месяца",
              "6 ядер 16 ГБ ОЗУ",
              "1 лицензионный ключ для одного устройства",
              "Сервер в Сингапуре",
            ],
            product_type: "BULLET_PRROF_RDP",
          }}
        />
      </div>
    </div>
  );
};
