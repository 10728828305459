import React from "react";
import { RefundReq } from "../../utils/Routes";
import SlideButton from "react-slide-button";

export default function ModalPOST({
  email,
  product_id,
  invoice_id,
  product_status,
}) {
  const [reason, setReason] = React.useState("Nothing Sent From Client Side");
  const ongRequest = () => {
    return {
      product_id: product_id,
      email: email,
      reason: reason,
    };
  };
  const [hide, setHide] = React.useState(false);
  const cancelModal = () => {
    window.location.reload();
  };
  const handleModal = () => {
    setHide(true);
    let data = ongRequest();
    try {
      fetch(RefundReq, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data),
      });
    } catch (error) {
      console.log(error.message);
      alert(error.message);
    } finally {
      window.location.reload();
    }
  };
  const [Captcha, setCaptcha] = React.useState(false);

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto transition-opacity ease-in duration-500 opacity-100 hover:backdrop-blur-sm ">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0   ">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          rounded-lg
        >
          <div className="absolute inset-0 bg-[#1e1e1e] opacity-75"></div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div className="inline-block align-bottom bg-slate-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full  ">
          {product_status !== "Confirmed" ? (
            <h2 className="px-4 py-10 text-xl text-red-400">
              Возврат невозможен: не оплачено{" "}
            </h2>
          ) : (
            <div>
              <div className="  px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <label
                  for="email-address-icon"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Ваш адрес электронной почты{" "}
                </label>
                <div class="relative">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      class="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="email-address-icon"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder={email}
                    disabled
                  />
                </div>
              </div>
              <div className="  px-4 sm:p-6 sm:pb-4">
                <label
                  for="email-address-icon"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Идентификатор счета-фактуры{" "}
                </label>
                <div class="relative">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      class="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A a2 2 0 00-1.997 1.884z"></path>
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 0 002-2V8.118z"></path>
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="email-address-icon"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder={invoice_id}
                    disabled
                  />
                </div>
              </div>
              <div className="  px-4 sm:p-6 sm:pb-4">
                <label
                  for="email-address-icon"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Причина возврата{" "}
                </label>
                <div class="relative">
                  <input
                    required
                    onChange={(e) => setReason(e.target.value)}
                    type="text"
                    id="reasoninput"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Напишите, почему вы хотите получить возврат"
                  />
                </div>
              </div>
            </div>
          )}
          <div className="w-[95%] mx-auto font-serif font-bold bg-slate-300">
            {" "}
            <SlideButton
              mainText="Slide Me"
              overlayText="H Y D R A"
              onSlideDone={function () {
                setCaptcha(true);
              }}
            />
          </div>
          <div className="bg-slate-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse justify-between">
            <button
              disabled={product_status !== "Confirmed" || Captcha == false}
              onClick={handleModal}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring ring-indigo-500 disabled:opacity-50 transition ease-in-out duration-150 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Отправить для возврата
            </button>
            <button
              onClick={cancelModal}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring ring-red-500 disabled:opacity-50 transition ease-in-out duration-150 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Отмена
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
