import React from "react";
import UpdateNoticeCard from "../../UpdateNoticeCard/UpdateNoticeCard";
import { NoticeUrl } from "../../utils/Routes";

export default function UpdateNotice() {
  const [datas, setNotice] = React.useState([]);
  
  const getNoticeData = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    fetch(NoticeUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setNotice(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getNoticeData();
  }, []);
  return (
    <section className="container mx-auto px-5 flex flex-col items-center my-16">
      <h2 className="text-3xl  text-center font-bold w-full hover:underline">
        Уведомление о Гидре:{" "}
      </h2>
      <div>
        <h6 className="text-lg font-semibold mx-6 min-w-[80%] my-[calc(2%)]">
          Будьте в курсе последних обновлений и объявлений на нашем сайте,
          проверив раздел «Уведомления». Здесь мы публикуем важные обновления и
          информацию, чтобы держать вас в курсе и быть в курсе любых изменений.
        </h6>
      </div>{" "}
      {datas.length > 0 ? (
        datas.map((data) => (
          <UpdateNoticeCard
            key={data?._id}
            title={data?.title}
            description={data?.desc}
            image1={data?.image1}
            image2={data?.image2}
            image3={data?.image3}
            image4={data?.image4}
            image5={data?.image5}
            date={data?.date}
          />
        ))
      ) : (
        <h2 className="text-center animate-pulse text-lg h-[calc(50%)] my-20 font-mono font-semibold">
          Уведомления загружаются . . .{" "}
        </h2>
      )}
    </section>
  );
}
