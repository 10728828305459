import React from "react";

const Stat = () => {
  return (
    <div className="container mx-auto px-6">
      <div className="flex justify-center">
        <div className="flex flex-col md:flex-row bg-[#19233A] ">
          <div className="stat">
            <div className="stat-figure text-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block w-8 h-8 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 10V3L4 14h7v7l9-11h-7z"
                ></path>
              </svg>
            </div>
            <div className="stat-title">Просмотры веб-сайта</div>
            <div className="stat-value text-primary">2.6M</div>
            <div className="stat-desc">На 17% больше, чем в прошлом месяце</div>
          </div>

          <div class="stat">
            <div class="stat-figure text-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                class="inline-block w-8 h-8 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                ></path>
              </svg>
            </div>
            <div class="stat-title">Новые пользователи</div>
            <div class="stat-value text-primary ">10,200</div>
            <div class="stat-desc">↗︎ 250 (22%)</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stat;
