import React, { useEffect, useState } from "react";
import { allTransactions } from "../../utils/Routes";
import LicenseKeyComponent from "../Dashboard/LicenseKeyComponent";
import { Link } from "react-router-dom";

const Transactions = () => {
  const [transactions, setAllTransacitons] = useState([]);
  const [userInfo, setUserInfo] = React.useState([]);

  const getUserdata = () => {
    let user = localStorage.getItem("user");
    if (user != null) {
      setUserInfo(JSON.parse(user));
    } else {
      window.location.href = "/login";
    }
  };

  const getTransactions = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    let user = JSON.parse(localStorage.getItem("user"));
    if (user != null) {
      fetch(allTransactions + "?email=" + user.email, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setAllTransacitons(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getTransactions();
    getUserdata();
  }, []);

  return (
    <div className="container mx-auto py-5">
      <div className="py-3 text-center mb-3">
        <h1 className="text-[30px] text-[bold]">
          {userInfo.fname + " " + userInfo.lname}
        </h1>
        <h1 className="text-[20px] text-[bold]">
          Электронная почта: {userInfo.email}
        </h1>
      </div>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Тип монеты
              </th>
              <th scope="col" className="px-6 py-3">
                Адрес кошелька{" "}
              </th>
              <th scope="col" className="px-6 py-3">
                ИДЕНТИФИКАТОР ТЕКСТА{" "}
              </th>
              <th scope="col" className="px-6 py-3">
                Тип версии{" "}
              </th>
              <th scope="col" className="px-6 py-3">
                Количество
              </th>
              <th scope="col" className="px-6 py-3">
                Лицензионный ключ{" "}
              </th>
            </tr>
          </thead>
          <tbody>
            {transactions?.map((trx, index) => (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <td class="px-6 py-4">{trx?.addressType}</td>
                <td class="px-6 py-4">{trx?.address}</td>
                <td class="px-6 py-4">
                  <a
                    className="text-blue-600"
                    target="blank"
                    href={`${trx?.tnxId}`}
                  >
                    {trx?.tnxId}
                  </a>
                </td>
                <td class="px-6 py-4">{trx?.version_type}</td>
                <td class="px-6 py-4">{trx?.amount}</td>

                <td className="px-6 p-4">
                  <LicenseKeyComponent licenceKey={trx?.licence_key} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Transactions;
