import React, { memo } from "react";
import ReactLinkify from "react-linkify";

const ReportDesc = memo(({ description }) => {
  const shortDescription = description.slice(0, 350);
  const [showMore, setShowMore] = React.useState(false);

  React.useEffect(() => {
    console.log(showMore);
  }, [showMore]);

  return (
    <div className="leading-relaxed">
      {showMore
        ? description.split("<br/>").map(function (item, idx) {
            return (
              <ReactLinkify>
                <span key={idx}>
                  {item}
                  <br />
                </span>
              </ReactLinkify>
            );
          })
        : shortDescription.split("<br/>").map(function (item, idx) {
            return (
              <ReactLinkify>
                <span key={idx}>
                  {item}
                  <br />
                </span>
              </ReactLinkify>
            );
          })}
      {description.length > 350 && (
        <button
          className="text-sm ml-4 font-mono font-bold"
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? "Показать меньше" : "Показать больше"}...
        </button>
      )}
    </div>
  );
});

export default ReportDesc;
