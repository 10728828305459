import React from "react";

const Terms = () => {
  return (
    <div className="max-h-[200px] overflow-auto border-2 p-3 text-gray-500">
      <p className="terms-text">
        В качестве условия использования вы обязуетесь не использовать Услуги
        HydraFlasher для любых целей, которые являются незаконными или запрещены
        настоящими Условиями. В качестве примера. а не в качестве ограничения.
        вы соглашаетесь не использовать Услуги:
        <br />
        <br />
        1. Оскорблять, преследовать, угрожать, выдавать себя за другое лицо или
        запугивать кого-либо. <br />
        2. Размещать или передавать. или способствовать вставке или передаче.
        любого Контента, который является клеветническим. дискредитирующим,
        непристойным, порнографическим, оскорбительным. оскорбительным,
        богохульным. или который нарушает какие-либо авторские права или права
        другого лица.
        <br />
        3. Общаться с представителями или другими пользователями в
        оскорбительной или ненормативной лексике.
        <br />
        4. Для любых целей, включая размещение или просмотр Контента, который не
        разрешен законами юрисдикции, в которой вы используете Услуги.
        <br />
        5. Публиковать или передавать или способствовать публикации или передаче
        любых сообщений, предназначенных или предназначенных для получения
        пароля, учетной записи или частной информации от любого пользователя.
        <br />
        6. Создавать или передавать нежелательный «спам» любому лицу или на
        любой URL-адрес. <br />
        7. Создавать несколько учетных записей с целью голосования за или против
        визуального Контента пользователей.
        <br />
        8. Использование нашего программного обеспечения на нескольких
        устройствах приведет к ограничению вашей лицензии.
        <br />
        9. Из-за пустого майнера иногда прошивка может не сработать. Тогда
        измените свой Кошелек и прошейте новый. Пустая прошивка не будет
        учитываться в ежедневном лимите подписки.
        <br />
        *Платная лицензия будет доступна в нашем разделе Магазин. Наш старый
        магазин Sellix закрыт*.
      </p>
    </div>
  );
};

export default Terms;
