import React from "react";
import { Link } from "react-router-dom";
import { loginRoute, getPublicIP } from "../../utils/Routes";
import { useNavigate } from "react-router-dom";
import SlideButton from "react-slide-button";

const Login = () => {
  // console.log(loginRoute)
  const [error, setError] = React.useState(false);
  const [Captcha, setCaptcha] = React.useState(false);

  const [errorMsg, seterrorMsg] = React.useState("");
  const navigate = useNavigate();
  const checkuser = () => {
    let user = localStorage.getItem("user");
    if (user != null) {
      window.location.reload();
      navigate("/dashboard");
    }
  };
  React.useEffect(() => {
    checkuser();
  }, []);
  checkuser();
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const userInfo = {
      email: e.target.email.value,
      password: e.target.password.value,
    };

    let ip = await getPublicIP();
    userInfo.current_ip = ip;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(userInfo),
    };
    fetch(loginRoute, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log("h: ", data);
        if (data.status == true) {
          let userdata = data.data;
          localStorage.setItem("user", JSON.stringify(userdata));
          window.location.href = "/dashboard";
        } else {
          setError(true);
          seterrorMsg(data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // e.target.reset()
  };
  return (
    <div className="container mx-auto px-5 flex justify-between   flex-col lg:flex-row">
      <div className="lg:w-1/2 flex justify-center items-center h-full flex-col">
        <img
          src="https://hydraflasher.com/logo512.png"
          className="w-[200px] animate-spin-slow py-6"
        />
        <h1 className="text-4xl font-extrabold text-white text-center ">
          Добро пожаловать в<span className="text-primary"> Hydra Flasher</span>
        </h1>
        {/* <p className="mt-4">
					Lorem ipsum, dolor sit amet consectetur adipisicing elit.
					Officiis, saepe!
				</p> */}
      </div>
      <div className="lg:w-1/2  my-5">
        <form
          onSubmit={handleFormSubmit}
          className="flex flex-col items-center justify-center h-full"
        >
          <h1 className="text-4xl font-extrabold text-white text-center">
            Авторизоваться
          </h1>
          <div
            className={`input bg-[#fd3232] input-bordered input-primary w-full max-w-xs mt-6 ${
              error ? "flex" : "hidden"
            } justify-center items-center`}
          >
            {errorMsg}
          </div>
          <input
            type="email"
            placeholder="Напишите нам по электронной почте..."
            name="email"
            required
            class="input input-bordered input-primary w-full max-w-xs mt-6"
          />
          <input
            type="password"
            name="password"
            placeholder="Пароль здесь..."
            required
            class="input input-bordered input-primary w-full max-w-xs mt-6"
          />
          <div className="w-full max-w-xs mt-6 rounded-lg my-2  mx-auto font-serif font-bold bg-slate-300">
            {" "}
            <SlideButton
              mainText="Slide Me"
              overlayText="H Y D R A"
              onSlideDone={function () {
                setCaptcha(true);
              }}
            />
          </div>
          <input
            disabled={Captcha == false}
            type="submit"
            class="input input-bordered input-primary w-full max-w-xs mt-6 cursor-pointer"
            value="Login"
          />
          {error && (
            <p className="text-red-500 text-center text-sm mt-2">{error}</p>
          )}
          <Link
            className="text-sm mt-2 text-secondary hover:underline"
            to="/register"
          >
            У вас нет учетной записи?{" "}
          </Link>
        </form>
      </div>
    </div>
  );
};

export default Login;
