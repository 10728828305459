import React from "react";
import { ScamReportPostUrl, loginRoute } from "../../utils/Routes";
import { useNavigate } from "react-router-dom";
import SlideButton from "react-slide-button";

export default function ReportModalPOST({ userInfo }) {
  const [isEligible, setisEligible] = React.useState(true);
  const [Captcha, setCaptcha] = React.useState(false);
  const [LastReport, setLastReport] = React.useState("");
  const [files, setFiles] = React.useState([]);
  const [reportTitle, SetreportTitle] = React.useState("");
  const [reportDetails, SetreportDetails] = React.useState("");

  const isEligibleChecker = () => {
    const currentDate = new Date();
    const dateString = userInfo.scam_reports[userInfo.scam_reports.length - 1]
      ?.report_date
      ? userInfo.scam_reports[userInfo.scam_reports.length - 1].report_date
      : "2003-05-29T06:10:42.676+00:00";
    const dateTimestamp = Date.parse(dateString);
    const differenceInDays = Math.floor(
      (currentDate.getTime() - dateTimestamp) / (24 * 60 * 60 * 1000)
    );

    setLastReport(differenceInDays);
    if (differenceInDays >= 30) {
      setisEligible(true);
    } else {
      setisEligible(false);
    }
  };
  React.useEffect(() => {
    isEligibleChecker();
  }, [isEligible]);

  const handleModal = (event) => {
    event.preventDefault();
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("images", files[i]);
    }
    formData.append("images", files);

    formData.append("title", reportTitle);

    formData.append("desc", reportDetails);
    formData.append("userMail", userInfo?.email);
    formData.append("userId", userInfo?._id);
    formData.append("date", new Date().toLocaleTimeString());

    fetch(ScamReportPostUrl, {
      method: "POST",

      body: formData,
    })
      .then((response) => {
        alert("ОТЧЕТ ПРЕДСТАВЛЕН ВЫПОЛНЕНО");

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body: JSON.stringify(userInfo),
        };
        fetch(loginRoute, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            // console.log("h: ", data);
            if (data.status == true) {
              let userdata = data.data;
              localStorage.setItem("user", JSON.stringify(userdata));
              // console.log(data.data);
              // window.location.reload();
              window.location.href = "/dashboard";
              // navigate('/dashboard');
            } else {
              alert("ERROR ONG: 86");
            }
          })
          .catch((err) => {
            console.log(err);
          });
        window.location.reload();
      })
      .catch((error) => {
        alert("NETWORK ERROR");
        console.log("ERR", error);
        window.location.reload();
      });
  };

  const navigate = useNavigate();

  const cancelModal = () => {
    navigate(0);
  };
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto transition-opacity ease-in duration-500 opacity-100 hover:backdrop-blur-sm ">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0   ">
        <div
          className="fixed inset-0 transition-opacity"
          aria-hidden="true"
          rounded-lg
        >
          <div className="absolute inset-0 bg-slate-800 opacity-75"></div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div className="inline-block align-bottom bg-gradient-to-r from-slate-400	 to-blue-500 rounded-lg backdrop-blur-3xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full  ">
          <h2 className="text-center text-xl py-2 font-bold bg-blue-100 text-blue-500  animate-pulse">
            Сообщить о мошенничестве{" "}
          </h2>
          {isEligible == false && (
            <small className="bg-red-100 text-red-500 animate-pulse px-3 font-bold rounded mx-4">
              Ваш последний отчет был отправлен {LastReport} дней назад.{" "}
              {30 - LastReport} осталось дней...
            </small>
          )}
          <div>
            <div className="  px-4 sm:p-6 sm:pb-4">
              <label
                for="email-address-icon"
                class="block mb-2 text-sm font-medium text-slate-900 dark:text-white"
              >
                Название отчета о мошенничестве{" "}
              </label>
              <div class="relative">
                <input
                  required
                  maxLength={60}
                  onChange={(e) => SetreportTitle(e.target.value)}
                  type="text"
                  id="reasoninput"
                  class="bg-gray-50 border border-gray-300 text-emerald-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Напишите подробности о мошенничестве"
                />
              </div>
            </div>
            {reportTitle == "" && (
              <small className="text-red-500 animate-pulse px-3 font-bold">
                Вы не можете оставить ПУСТОЕ название
              </small>
            )}
            <div className="  px-4 sm:p-6 sm:pb-4">
              <label
                for="email-address-icon"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Напишите подробности о мошенничестве
              </label>
              <div class="relative">
                <textarea
                  maxLength={500}
                  required
                  onChange={(e) => SetreportDetails(e.target.value)}
                  type="text"
                  id="reportDetails"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Напишите подробности о мошенничестве"
                />
              </div>
            </div>
            {reportDetails == "" && (
              <small className="text-red-500 animate-pulse px-3 font-bold">
                Вы не можете отправить заявку без упоминания подробностей
              </small>
            )}
            <div className="  px-4 sm:p-6 sm:pb-4">
              <label
                for="email-address-icon"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Загрузите ваши изображения
              </label>
              <div class="relative">
                <input
                  required
                  onChange={(e) => setFiles(e.target.files)}
                  type="file"
                  accept="image/*"
                  // id="reasoninput"
                  multiple
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-2 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Напишите, почему вы хотите получить возврат"
                />
              </div>
            </div>

            <div class="grid grid-cols-3 m-4">
              {files.length > 0 &&
                [...files].map((x, i) => (
                  <button key={i} className="bg-purple-50 text-purple-500 m-2">
                    {x?.name}
                  </button>
                ))}
            </div>

            {files.length > 4 && (
              <small className="text-red-500 animate-pulse px-3 font-bold">
                Вы можете загрузить максимум 4 изображения.
              </small>
            )}
          </div>

          <div className="w-[95%] mx-auto font-serif font-bold">
            {" "}
            <SlideButton
              mainText="Slide Me"
              overlayText="H Y D R A"
              onSlideDone={function () {
                setCaptcha(true);
              }}
            />
          </div>
          <div className="bg-blue-500 border-2 border-blue-400 rounded-full px-4 py-3 my-2 sm:px-6 sm:flex sm:flex-row-reverse justify-between">
            <button
              disabled={
                files.length > 4 ||
                files.length == 0 ||
                reportDetails == "" ||
                reportTitle == "" ||
                isEligible == false ||
                Captcha == false
              }
              onClick={handleModal}
              className="w-full inline-flex disabled:animate-bounce justify-center rounded-md border-2 border-white   shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring ring-indigo-500 disabled:opacity-50 transition ease-in-out duration-150 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Отправить отчет о мошенничестве
            </button>
            <button
              onClick={cancelModal}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring ring-red-500 disabled:opacity-50 transition ease-in-out duration-150 sm:ml-3 sm:w-auto sm:text-sm "
            >
              Отмена
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
