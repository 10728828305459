import React from "react";

export default function Refund() {
  return (
    <div className="container mx-auto px-5 flex flex-col items-center my-16">
      <p className="text-3xl lg:w-1/2 text-center font-bold w-full ">
        Политика возврата средств{" "}
      </p>

      <div>
        <h4 className="text-xl font-bold my-[calc(2%)]">
          {" "}
          Спасибо, что выбрали наше программное обеспечение. Мы ценим ваше
          удовлетворение и хотим убедиться, что вы полностью довольны своей
          покупкой. Однако, если вы обнаружите, что наше программное обеспечение
          не соответствует вашим потребностям, мы предлагаем политику возврата
          средств. Наша политика возврата средств заключается в следующем:
        </h4>
        <p>
          1: Вы имеете право на полный возврат средств в течение 7 дней с
          момента покупки, при условии, что вы никому не передали свой
          лицензионный ключ.
        </p>
        <p>
          2: Если вы активировали свой лицензионный ключ, а программное
          обеспечение не работает должным образом, мы поработаем с вами, чтобы
          решить проблему. Если мы не сможем решить проблему в разумные сроки,
          вы можете иметь право на возврат средств.
        </p>{" "}
        <p>
          3: Возврат средств будет произведен тем же способом, что и
          первоначальный платеж. Пожалуйста, подождите до 3 рабочих дней, пока
          возврат средств не появится на вашем счете.{" "}
        </p>
        <p>
          4: Мы оставляем за собой право отказать в возврате средств, если мы
          считаем, что программное обеспечение было использовано не по
          назначению или если запрос на возврат средств был сделан после
          7-дневного периода. Обратите внимание, что если вы приобрели наше
          программное обеспечение у стороннего поставщика (неавторизованного),
          возврат средств невозможен. Если у вас есть какие-либо вопросы или
          опасения по поводу нашей политики возврата средств, свяжитесь с нами
          по адресу{" "}
          <a href="https://t.me/hydraflasher" className="hover:underline">
            [https://t.me/hydraflasher]
          </a>
        </p>
      </div>
      <a
        className="buy-with-crypto bg-secondary  focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900"
        href={"/dashboard"}
      >
        Подать заявку на возврат средств{" "}
      </a>
    </div>
  );
}
