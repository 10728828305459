import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ManageRoute from "../../utils/ManageRoutes";
import { AiOutlineHome, AiOutlineShopping } from "react-icons/ai";
import {
  MdOutlineCloudDownload,
  MdOutlineMedicalServices,
  MdOutlineRateReview,
} from "react-icons/md";
import { RiContactsBook2Line } from "react-icons/ri";
import { IoIosContact } from "react-icons/io";
import { SiShopify } from "react-icons/si";
import { Footer } from "../Footer/Footer";
import BlackFriday from "../blackfriday_meganav/main";

const Navbar = () => {
  const [isLogin, setIsLogin] = useState(false);
  const checkuser = () => {
    let user = localStorage.getItem("user");
    if (user != null) {
      setIsLogin(true);
    }
  };
  React.useEffect(() => {
    checkuser();
  });
  const navLink = (
    <>
      <li>
        <NavLink
          className="px-2 md:text-sm py-1 font-mono rounded-none font-bold  mt-6 md:mt-0 "
          to="/"
        >
          <AiOutlineHome className="lg:hidden" /> Дом
        </NavLink>
      </li>
      <li>
        <NavLink
          className="px-2 md:text-sm py-1 font-mono rounded-none font-bold  mt-6 md:mt-0 "
          to="/download"
        >
          <MdOutlineCloudDownload className="lg:hidden" /> Скачать
        </NavLink>
      </li>
      <li>
        <NavLink
          className="px-2 md:text-sm py-1 font-mono rounded-none font-bold  mt-6 md:mt-0 "
          to="/services"
        >
          <MdOutlineMedicalServices className="lg:hidden" /> Услуги
        </NavLink>
      </li>
      <li>
        <NavLink
          className="px-2 md:text-sm py-1 font-mono rounded-none font-bold  mt-6 md:mt-0 "
          to="/shop"
        >
          <AiOutlineShopping className="lg:hidden" /> Магазин
        </NavLink>
      </li>
      <li>
        <a
          className="px-2 md:text-sm py-1 font-mono rounded-none font-bold  mt-6 md:mt-0 "
          href="https://hydra-flasher.sellix.io/"
          target="_blank"
        >
          <SiShopify className="lg:hidden" /> Селликс
        </a>
      </li>
      <li>
        <NavLink
          className="px-2 md:text-sm py-1 font-mono rounded-none font-bold  mt-6 md:mt-0 "
          to="/videos"
        >
          <MdOutlineMedicalServices className="lg:hidden" /> Видео
        </NavLink>
      </li>
      <li>
        <NavLink
          className="px-2 md:text-sm py-1 font-mono rounded-none font-bold  mt-6 md:mt-0 "
          to="/reviews"
        >
          <MdOutlineRateReview className="lg:hidden" /> Обзоры
        </NavLink>
      </li>
      <li>
        <NavLink
          className="px-2 md:text-sm py-1 font-mono rounded-none font-bold  mt-6 md:mt-0 "
          to="/refund-policy"
        >
          <MdOutlineMedicalServices className="lg:hidden" /> Возвращать деньги
        </NavLink>
      </li>

      <li>
        <NavLink
          className="px-2 md:text-sm py-1 font-mono rounded-none font-bold  mt-6 md:mt-0 "
          to="/report-scam"
        >
          <MdOutlineMedicalServices className="lg:hidden" />
          Сообщить о мошенничестве
        </NavLink>
      </li>

      <li className="lg:hidden">
        <NavLink
          className="px-2 md:text-sm py-1 font-mono rounded-none font-bold  mt-6 md:mt-0  "
          to="/notice-update"
        >
          <MdOutlineMedicalServices className="lg:hidden" /> Уведомление
        </NavLink>
      </li>

      <li className="lg:hidden">
        <NavLink
          className="px-2 md:text-sm py-1 font-mono rounded-none font-bold  mt-6 md:mt-0 "
          to="/news"
        >
          <MdOutlineMedicalServices className="lg:hidden" /> Новости
        </NavLink>
      </li>

      <div className="dropdown hidden lg:block dropdown-hover">
        <label
          tabIndex={0}
          className="p-1 font-mono rounded-none font-bold  mt-6 md:mt-0 "
        >
          Особенность
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
        >
          <li>
            <NavLink
              className="px-2 md:text-sm py-1 font-mono rounded-none font-bold  mt-6 md:mt-0 "
              to="/notice-update"
            >
              <MdOutlineMedicalServices className="lg:hidden" /> Уведомление
            </NavLink>
          </li>

          <li>
            <NavLink
              className="px-2 md:text-sm py-1 font-mono rounded-none font-bold  mt-6 md:mt-0 "
              to="/news"
            >
              <MdOutlineMedicalServices className="lg:hidden" /> Новости
            </NavLink>
          </li>
        </ul>
      </div>
      <li>
        {!isLogin ? (
          <NavLink
            className="px-2 md:text-sm py-1 font-mono rounded-none font-bold  mt-6 md:mt-0 "
            to="/login"
          >
            <IoIosContact className="lg:hidden" /> Авторизоваться
          </NavLink>
        ) : (
          <NavLink
            className="px-2 md:text-sm py-1 font-mono rounded-none font-bold  mt-6 md:mt-0 "
            to="/dashboard"
          >
            <IoIosContact className="lg:hidden" /> Панель инструментов
          </NavLink>
        )}
      </li>
    </>
  );
  return (
    <>
      <div class="drawer">
        <input id="my-drawer-3" type="checkbox" class="drawer-toggle" />
        <div class="drawer-content flex flex-col ">
          <div className="pt-4 pb-2">
            <div class="w-full navbar  mx-auto px-5">
              <div class="flex-none lg:hidden">
                <label for="my-drawer-3" class="btn btn-square btn-ghost">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    class="inline-block w-6 h-6 stroke-current"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    ></path>
                  </svg>
                </label>
              </div>
              <div class="flex-1 px-2 text-1xl font-extrabold common text-primary flex justify-start items">
                <img
                  src="/logo512.png"
                  className="w-[50px] h-[50px] mr-2 animate-spin-slow"
                />
                Hydra Flasher
              </div>
              <div class="flex-none hidden lg:block">
                <ul class="menu menu-horizontal">{navLink}</ul>
              </div>
            </div>
          </div>
          <ManageRoute />
          <Footer />
        </div>
        <div class="drawer-side">
          <label for="my-drawer-3" class="drawer-overlay"></label>
          <ul class="menu p-4 overflow-y-auto bg-base-100 w-2/3">{navLink}</ul>
        </div>
      </div>
    </>
  );
};

export default Navbar;
